@import "src/scss/module";

.hero {
  position: relative;
  min-height: 500px;
  min-width: 100vw;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10vh 10vw;

  .bg_image {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 500px;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    z-index: -1;
    object-fit: cover;
  }

  .subtitle {
    color: white;
    margin-bottom: 12px;
  }

  .title {
    color: white;
    margin-bottom: 5vh;
  }
}

@media (max-width: 992px) {
  .hero {
    .bg_image {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform: translate(0, 0);
    }
  }

}

@media (max-width: 768px) {

  .hero {
    .title {
      margin-bottom: 0;
    }
  }

}

@media (max-width: 576px) {

  .hero {
    min-height: 380px;
    padding: 11vh 5vw;
  }
}